 <template>
	<el-dialog top="1vh" title="修改准驾车型" width="500px" :visible.sync="is_show_in_page">
		<el-form class="add_form" status-icon label-position="left" label-width="80px">
			<el-form-item class="el_form_item" label="准驾车型">
				<el-input class="el_input" v-model="dl_class" clearable></el-input>
			</el-form-item>
			<div class="bottom_btns">
				<el-button type="primary" @click="edit_view_sub">提交</el-button>
			</div>
		</el-form>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			driver_class:Object,
			is_show:Number
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,

				//准驾车型id
				driver_class_id:'',

				//准驾车型
				dl_class:'',
			}
		},
		watch:{
			driver_class(new_data){
				if(new_data){
					this.driver_class_id=new_data.id;
					this.dl_class=new_data.dl_class;
				}
			},
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
				}
			}
		},
		methods:{

			//提交
			edit_view_sub(){

				if(!this.dl_class.trim()){
					this.$my.other.msg({
						type:'warning',
						str:'缺少准驾车型'
					});
					return;
				}

				//提交
				this.$my.net.req({
					data:{
						mod:'truck_driver',
						ctr:'driver_class_edit_admin',
						driver_class_id:this.driver_class_id,
						dl_class:this.dl_class.trim(),
					},
					callback:(data)=>{

						//关闭弹出层
						this.is_show_in_page=false;

						//通知
						this.$emit('end_of_sub',{})
					}
				});
			},
		}
	}
</script>

<style lang="scss" scoped>

	//底部提交按钮
	.bottom_btns{
		width: 100%;
		text-align: center;
	}
</style>